import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import styles from "./Home.module.css"

import BackgroundWebP from "../images/crossroad_krk.webp"

const Section = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: auto;
 
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
`

const ScrollToDiscoverContainer = styled.div`
    position: absolute;
    display:inline-flex;
    z-index: 100;
    bottom: 10rem;
    transform: rotate(270deg);
    font-size: 0.8rem;
    left: -1.4rem;
    text-transform: uppercase;
    font-family: IBM Plex Sans;
    
    @media (max-width: 480px) {
      display: none;
    }
`

const TextContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  padding-left: 10rem;
  padding-right: 1rem;
  width: 65%;
  max-width: 900px;
  
  @media (max-width: 768px) {
    margin-top: 20%;
    width: 100%;
  
     h2 {
        margin-top: 0;
     }
  }
  
  @media (max-width: 480px) {
      padding: 1.5rem 2rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      
      h3 {
        display: none;
      }
      
      h2 {
        margin: 0;
        font-size: 1.35rem;
      }
  }
`

const ImageContainer = styled.div`
  width: 35%;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledImage = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: left;
    height: 100vh;
    
  @media (max-width: 768px) {
    max-height: 350px;
  }
    
`

const HeroText = ({ text }) => {
  return (
    <TextContainer>
      <h3 className="noselect">Welcome to Geekchester</h3>
      <h2 className="noselect">{text}</h2>
    </TextContainer>
  )
}

HeroText.propTypes = {
  text: PropTypes.string.isRequired,
}

const Home = ({ index }) => (
  <StaticQuery
    query={graphql`
      query LandingNewHeroQuery {
        contentfulHero {
          heroText
          heroBackground {
            file {
              url
            }
          }
        }
      }
    `}
    render={data => {
      const text = data.contentfulHero.heroText
      const backgroundImage = data.contentfulHero.heroBackground.file.url

      return (
        <Section className="section" style={{ zIndex: index }} id="home-anchor">
          <HeroText text={text} />

          <ImageContainer>
            <picture>
              <source srcSet={BackgroundWebP} type="image/webp" />
              <StyledImage src={backgroundImage} alt="" />
            </picture>
          </ImageContainer>

          <ScrollToDiscoverContainer>
            <div className={styles.scrollDirection} />
            <div className="noselect">Scroll to discover</div>
          </ScrollToDiscoverContainer>
        </Section>
      )
    }}
  />
)

export default Home
